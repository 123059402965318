import React, {Component} from 'react';
import './App.css';
import SignInForm from './components/SignInForm';
import styled from '@emotion/styled/macro';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Navbar from "./components/Navbar"
import image from "./image/NiftyBank.png"

library.add(faEye, faEyeSlash);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
`;

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar/>
        <Container>
          <img src={image}/>
          <SignInForm/> 
          </Container>
        
      </div>
    );
  }
}

export default App;
